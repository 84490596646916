import ProductOptions from '/components/products/show/product-form/ProductOptions';
import PropTypes from 'prop-types';

export default function ProductOptionsStandard({
	activeHandle,
	hasJoinedProducts = false,
	hasSingleSizeOption = false,
	isCouplesPage = false,
	joinedProducts = [],
	presetSize,
	product,
	selectedOptions,
	setBackInStockSettings,
	setSelectedOptions,
}) {
	return (
		<>
			<ProductOptions
				activeHandle={activeHandle}
				hasSingleSizeOption={hasSingleSizeOption}
				isCouplesPage={isCouplesPage}
				primary={true}
				presetSize={presetSize}
				product={product}
				selectedOptions={selectedOptions}
				setBackInStockSettings={setBackInStockSettings}
				setSelectedOptions={setSelectedOptions}
				showFromPrice={hasJoinedProducts}
				showNoneOption={false}
				showProductType={hasJoinedProducts}
			/>
			{joinedProducts.map((jp, i) => {
				return (
					<ProductOptions
						activeHandle={activeHandle}
						isJoinedProduct={true}
						key={i}
						isCouplesPage={isCouplesPage}
						primary={false}
						product={jp}
						selectedOptions={selectedOptions}
						setBackInStockSettings={setBackInStockSettings}
						setSelectedOptions={setSelectedOptions}
						showFromPrice={true}
						showNoneOption={true}
						showProductType={true}
					/>
				);
			})}
		</>
	);
}

ProductOptionsStandard.propTypes = {
	activeHandle: PropTypes.string,
	hasJoinedProducts: PropTypes.bool,
	hasSingleSizeOption: PropTypes.bool,
	isCouplesPage: PropTypes.bool,
	joinedProducts: PropTypes.array,
	presetSize: PropTypes.string,
	product: PropTypes.object.isRequired,
	selectedOptions: PropTypes.array.isRequired,
	setBackInStockSettings: PropTypes.func,
	setSelectedOptions: PropTypes.func.isRequired,
};
